<template>
    <div>
        <ts-page-title
            :title="$t('attendanceDetail.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('attendanceDetail.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper class="row">
                <div class="col-md-2 form-group tw-px-2">
                    <label
                        class="form-col-label control-label required tw-mb-2"
                        >{{ $t("attendanceDetail.dateFrom") }}</label
                    >
                    <DatePicker
                        class="tw-w-full"
                        :value="model.dateFrom"
                        @on-change="onChangeDateFrom"
                        format="dd-MM-yyyy"
                        type="date"
                        :class="{
                            'ivu-form-item-error': errors.has('dateFrom')
                        }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('dateFrom')"
                    >
                        {{ errors.first("dateFrom") }}
                    </div>
                </div>
                <div class="col-md-2 form-group tw-px-2">
                    <label
                        class="form-col-label control-label required tw-mb-2"
                        >{{ $t("attendanceDetail.dateTo") }}</label
                    >
                    <DatePicker
                        class="tw-w-full"
                        :value="model.dateTo"
                        @on-change="onChangeDateTo"
                        format="dd-MM-yyyy"
                        type="date"
                        :class="{
                            'ivu-form-item-error': errors.has('dateTo')
                        }"
                    />
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('dateTo')"
                    >
                        {{ errors.first("dateTo") }}
                    </div>
                </div>
                <div class="col-md-2 form-group tw-px-2">
                    <label
                        class="form-col-label control-label required tw-mb-2"
                        >{{
                            $t("attendanceDetail.organisationalStructure")
                        }}</label
                    >
                    <Select
                        v-model="model.org_chart_structure_id"
                        clearable
                        @on-change="getEmployeeByOrg()"
                        :class="{
                            'ivu-form-item-error': errors.has(
                                'org_chart_structure_id'
                            )
                        }"
                    >
                        <Option
                            v-for="(orgChartStructure,
                            index) in orgChartStructures"
                            :key="index"
                            :value="orgChartStructure.org_chart_structure_id"
                            >{{ orgChartStructure.org_chart_structure }}
                        </Option>
                    </Select>
                    <div
                        class="ivu-form-item-error tw-text-red-600"
                        v-if="errors.has('org_chart_structure_id')"
                    >
                        {{ errors.first("org_chart_structure_id") }}
                    </div>
                </div>
                <div class="col-md-2 form-group tw-px-2">
                    <label class="form-col-label control-label tw-mb-2">{{
                        $t("attendanceDetail.employeeName")
                    }}</label>
                    <Select
                        v-model="model.employee_id"
                        clearable
                        multiple
                        filterable
                        placeholder="All"
                        :loading="EmployeeLoading"
                        :not-found-text="
                            EmployeeLoading ? '' : 'No options available'
                        "
                    >
                        <Option
                            v-for="(employee, index) in employees"
                            :key="index"
                            :value="employee.employee_id"
                            >{{ employee.employee_name_en }}
                        </Option>
                    </Select>
                </div>
                <div class="col-md-2 form-group tw-px-2 tw-pt-7">
                    <Button class="tw-mb-1" type="primary" @click="fetchData"
                        >{{ $t("view") }}
                    </Button>
                    <Button
                        class="tw-mb-1 tw-ml-3"
                        type="success"
                        ghost
                        @click="fetchData"
                    >
                        <Icon type="ios-document-outline" />
                        {{ $t("excel") }}
                    </Button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                border
                stripe
            >
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
    name: "attendance-detail",
    components: {},
    data() {
        return {
            errors: new Errors(),
            loading: false,
            EmployeeLoading: false,
            model: {
                dateFrom: moment()
                    .startOf("month")
                    .format("DD-MM-YYYY"),
                dateTo: moment()
                    .endOf("month")
                    .format("DD-MM-YYYY"),
                org_chart_structure_id: null,
                employee_id: null
            }
        };
    },
    computed: {
        ...mapState("report/attendanceDetail", [
            "resources",
            "pagination",
            "orgChartStructures",
            "employees"
        ]),
        columns() {
            return [
                {
                    title: this.$t("attendanceDetail.cardNumber"),
                    key: "card_number",
                    width: "120"
                },
                {
                    title: this.$t("attendanceDetail.position"),
                    key: "position",
                    width: "100"
                },
                {
                    title: this.$t("attendanceDetail.shift"),
                    key: "shift",
                    width: "100"
                },
                {
                    title: this.$t("attendanceDetail.dateScan"),
                    key: "data_scan",
                    width: "100"
                },
                {
                    title: this.$t("attendanceDetail.t1"),
                    align: "center",
                    children: [
                        {
                            title: this.$t("attendanceDetail.checkIn"),
                            align: "center",
                            width: "100",
                            key: "t1"
                        },
                        {
                            title: this.$t("attendanceDetail.checkOut"),
                            align: "center",
                            width: "100",
                            key: "t2"
                        }
                    ]
                },
                {
                    title: this.$t("attendanceDetail.total1"),
                    key: "total1",
                    width: "100",
                    align: "center"
                },
                {
                    title: this.$t("attendanceDetail.t2"),
                    align: "center",
                    children: [
                        {
                            title: this.$t("attendanceDetail.checkIn"),
                            align: "center",
                            width: "100",
                            key: "t3"
                        },
                        {
                            title: this.$t("attendanceDetail.checkOut"),
                            align: "center",
                            width: "100",
                            key: "t4"
                        }
                    ]
                },
                {
                    title: this.$t("attendanceDetail.total2"),
                    key: "total2",
                    width: "100",
                    align: "center"
                },
                {
                    title: this.$t("attendanceDetail.t3"),
                    align: "center",
                    children: [
                        {
                            title: this.$t("attendanceDetail.checkIn"),
                            align: "center",
                            width: "100",
                            key: "t5"
                        },
                        {
                            title: this.$t("attendanceDetail.checkOut"),
                            align: "center",
                            width: "100",
                            key: "t6"
                        }
                    ]
                },
                {
                    title: this.$t("attendanceDetail.total3"),
                    key: "total3",
                    width: "100",
                    align: "center"
                },
                {
                    title: this.$t("attendanceDetail.t4"),
                    align: "center",
                    children: [
                        {
                            title: this.$t("attendanceDetail.checkIn"),
                            align: "center",
                            width: "100",
                            key: "t7"
                        },
                        {
                            title: this.$t("attendanceDetail.checkOut"),
                            align: "center",
                            width: "100",
                            key: "t8"
                        }
                    ]
                },
                {
                    title: this.$t("attendanceDetail.total4"),
                    key: "total4",
                    width: "100",
                    align: "center"
                },
                {
                    title: this.$t("attendanceDetail.attendance"),
                    key: "attendance",
                    width: "130",
                    align: "center"
                },
                {
                    title: this.$t("attendanceDetail.presentStatus"),
                    key: "presentStatus",
                    width: "130",
                    align: "center"
                }
            ];
        }
    },
    methods: {
        ...mapActions("report/attendanceDetail", [
            "getOrgChartStructure",
            "getEmployee",
            "clearEmployee"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getOrgChartStructure();
            this.loading = false;
        },
        async getEmployeeByOrg() {
            this.model.employee_id = null;
            this.EmployeeLoading = true;
            await this.clearEmployee();
            await this.getEmployee({
                id: this.model.org_chart_structure_id
                    ? this.model.org_chart_structure_id
                    : ""
            });
            this.EmployeeLoading = false;
        },
        onChangeDateFrom(data) {
            this.model.dateFrom = data;
        },
        onChangeDateTo(data) {
            this.model.dateTo = data;
        },
        async fetchData(attributes) {
            this.loading = true;
            this.errors = new Errors();
            this.$store
                .dispatch("report/attendanceDetail/fetch", {
                    data: this.model,
                    // date_from: this.dateFrom,
                    // date_to: this.dateTo,
                    // org_chart_structure_id: this.org_chart_structure_id,
                    // employee_id: this.employee_id,
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "DAILY ATTENDANCES",
                desc: not.text
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("report/attendanceDetail/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    }
};
</script>
